

const requestJSON = require("./requestJSON");

const getURL = (entity, action) => {
	return "https://api.iamnet.ph/emailer/"+action+"/"+entity;
}

const sendEmail = (entity, pathlist, datalist, destlist, jwtToken) => {
	return requestJSON.send( "https://sendemailer.api.iamnet.ph/sendfile/"+entity, "POST",{
			"filelist": pathlist,
			"datalist": datalist,
			"destlist": destlist
		}, jwtToken);
}

const saveFile = (fname, type, entity, name, jwtToken) => {
	return requestJSON.savefile( fname, getURL(entity, type) , "POST",{"payloadname": name}, jwtToken);
}

const getFile = (type, entity, name, jwtToken) => {
	return requestJSON.send( getURL(entity, type) , "POST",{"payloadname": name}, jwtToken);
}

const uploadFile = (entity, destpath, fname, base64data, contenttype, jwtToken) => {
	return requestJSON.send( getURL(entity, "upload") , "POST",{"payloadname": fname, "payloaddata": base64data, "payloadpath": destpath, "payloadtype": contenttype}, jwtToken);
}

const generateHTML = (entity, name, data, jwtToken) => {
	return requestJSON.send( getURL(entity, "generate") , "POST",{"payloadname": name, "payloaddata": data}, jwtToken);
}

const listFiles = (entity, destpath, jwtToken) => {
	return requestJSON.send( getURL(entity, "list") , "POST",{"payloadpath": destpath}, jwtToken);
}

module.exports = {
	sendEmail,
	saveFile,
	getFile,
	uploadFile,
	listFiles,
	generateHTML
}
